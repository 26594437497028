(function () {
    let _this = {};

    function HelpersAllTapController() {
        const _LOCATION_ = window.location, _SOURCE_ = '';
        _this = this;
        /** @namespace window.jQuery */
        _this.$jQuery = window.jQuery;
        _this.$createJs = window.createjs;
        _this.$FormData = (new FormData());
        _this.$XMLHttpRequest = (new XMLHttpRequest());
        _this.$timer = [];
        _this.$listeners = [];
        _this.$effectFadeIn = null;
        _this.$hours = (new Date()).getHours();
        _this.$page_default = () => {
        };
        _this.state = {
            uri: `${_LOCATION_.protocol}//${_LOCATION_.host}${_SOURCE_}`,
            _this_index: {}
        };

        /**
         *
         * Include app.css in head of iFrame.
         */
        _this.$jQuery('#load_iframe_index').on('load', () => {
            _this.$jQuery('<link/>', {
                media: 'all',
                type: 'text/css',
                rel: 'stylesheet',
                href: `${_this.state.uri}/public/css/app.min.css`
            }).appendTo(_this.$jQuery('#load_iframe_index').contents().find('head'));
        });
    }

    /**
     *
     */
    HelpersAllTapController.prototype = {
        /**
         *
         */
        constructor: HelpersAllTapController,

        /**
         *
         * @private
         */
        void_: () => {
        },

        /**
         *
         * @param state
         * @param callback
         */
        setState: (state, callback) => {
            _this.state = state;
            (typeof(callback) === 'function') && callback(true);
        },

        /**
         *
         * @param object
         */
        onLoad: (object) => {
            const {full_screen, library} = object;
            full_screen && _this.fullScreen();
            _this.setState({..._this.state, ...library});
        },

        /**
         *
         * @param field
         * @returns {string}
         */
        getState: (field) => {
            return _this.verifyField(_this.state, field, false, null);
        },

        /**
         *
         * @param object
         * @param callback
         */
        setStates: (object, callback) => {
            /**
             *
             * @param state
             * @param object
             * @param callback
             */
            const changeState = (state, object, callback) => {
                if (_this.isObjectIf(object)) {
                    _this.defineObject(object);

                    let
                        i = 0,
                        changed = false;

                    for (let key in object) {
                        ++i;
                        if (object.hasOwnProperty(key)) {
                            if (state.hasOwnProperty(key) && _this.isObjectIf(state[key])) {
                                changeState((_this.isObject(state[key]) ? state[key] : state), object[key], (value) => {
                                    state = {...state, [key]: value};
                                    changed = true;
                                });
                            } else if (state.hasOwnProperty(key) && Array.isArray(state[key]) && Array.isArray(object[key])) {
                                state = {...state, [key]: [...state[key], ...object[key]]};
                                changed = true;
                            } else {
                                state = {...state, [key]: object[key]};
                                changed = true;
                            }

                            if (changed && (object.length === i)) {
                                callback(state);
                            }
                        }
                    }
                } else {
                    callback(!_this.isEmpty(object) ? object : null);
                }
            };

            changeState(_this.state, object, (state) => {
                _this.setState(state, () => {
                    (typeof(callback) === 'function') && callback(true);
                });
            });
        },

        /**
         *
         * @param alias
         * @param callback
         * @param delay
         */
        throttle: (alias, callback, delay) => {
            _this.$timer[alias] && clearTimeout(_this.$timer[alias]);
            _this.$timer[alias] = setTimeout(() => {
                (typeof(callback) === 'function') && callback(true);
            }, delay || 3000);
        },

        /**
         *
         * @param alias
         */
        clearThrottle: (alias) => {
            clearTimeout(_this.$timer[alias]);
        },

        /**
         *
         * @param array
         * @param index
         * @param length
         * @param done
         * @param always
         */
        recursiveSyncMap: (array, index, length, done, always) => {
            done(array[index], () => {
                if (length > ++index) {
                    _this.recursiveSyncMap(array, index, length, (item, next, stop) => {
                        done(item, next, stop);
                    }, () => {
                        (typeof(always) === 'function') && always(true);
                    })
                } else {
                    (typeof(always) === 'function') && always(true);
                }
            }, () => {
                (typeof(always) === 'function') && always(true);
            });
        },

        /**
         *
         * @param value
         * @returns {boolean}
         */
        isEmpty: (value) => {
            return !(!!value && (typeof(value) !== 'undefined') && (value !== null));
        },

        /**
         *
         * @param array
         * @returns {boolean}
         */
        isArrayIf: (array) => {
            return (!!array && Array.isArray(array) && Boolean(array.length));
        },

        /**
         *
         * @param object|array-like
         * @returns {boolean}
         */
        isObjectIf: (object) => {
            return (!!object && ((typeof(object) === 'object') && (object !== null) && ((typeof(object.length) !== 'undefined' && Boolean(object.length)) || Boolean(Object.keys(object).length))));
        },

        /**
         *
         * @param object
         * @returns {boolean}
         */
        isObject: (object) => {
            return (!!object && (typeof(object) === 'object') && (object !== null));
        },

        /**
         *
         * @param object
         * @param method
         * @returns {boolean}
         */
        isFunction: (object, method) => {
            return (!!object && (typeof(object[method]) === 'function'));
        },

        /**
         *
         * @param element
         * @returns {boolean}
         */
        isElement: (element) => {
            return (!!element && (typeof(element.nodeType) !== 'undefined') && (element.nodeType === Node.ELEMENT_NODE || element.nodeType === Node.DOCUMENT_NODE));
        },

        /**
         *
         * @param object
         * @returns {boolean}
         */
        isXMLHttpRequest: (object) => {
            return (_this.isObject(object) && (typeof(object.constructor) !== 'undefined') && (object.constructor.name === 'XMLHttpRequest' || object.constructor.name === 'XMLHttpRequestUpload'));
        },

        isMobile: () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(window.navigator.userAgent);
        },

        /**
         *
         * @param string
         * @returns {boolean}
         */
        isIntegerOrFloat: (string) => {
            return (/^(-)?(\d+)(\.)?(\d+)?$/g).test(string);
        },

        /**
         *
         * @param string
         * @returns {boolean}
         */
        isIdCompound: (string) => {
            return (/^(\d+)([A-Z])$/g).test(string);
        },

        /**
         *
         * @param string
         * @returns {boolean}
         */
        isInteger: (string) => {
            return (/^(\d+)$/g).test(string);
        },

        /**
         *
         * @param string
         * @returns {*|boolean|number}
         */
        toInteger: (string) => {
            string = string && string.toString().replace(/,/g, '.').replace(/[^\d\.-]/g, '');
            return _this.isIntegerOrFloat(string) && Math.round(string);
        },

        /**
         *
         * @param string
         * @returns {*|boolean|string}
         */
        toIntegerOrFloat: (string) => {
            string = string && string.toString().replace(/,/g, '.').replace(/[^\d\.-]/g, '');
            return _this.isIntegerOrFloat(string) && parseFloat(string);
        },

        /**
         *
         * @param object
         */
        defineObject: (object) => {
            (_this.isObjectIf(object) && !object.hasOwnProperty('length')) && Object.defineProperty(object, 'length', {
                get: () => {
                    return Object.keys(object).length;
                }
            });
        },

        /**
         *
         * @param element
         * @param type
         * @param reference
         * @param listener
         * @param data
         */
        addListener: (element, type, reference, listener, data) => {
            const setListener = () => {
                if (_this.isElement(element) || _this.isXMLHttpRequest(element) || _this.isObjectIf(element)) {
                    let passive = false;
                    if (type === 'touchstart') {
                        passive = (Modernizr.passiveeventlisteners ? {passive: true} : false);
                    }
                    element.removeEventListener(type, listener);
                    element.addEventListener(type, listener, passive);
                    _this.$listeners = [..._this.$listeners, ...[{
                        reference: reference,
                        listener: element,
                        data: data
                    }]];
                }
            };

            if (_this.isArrayIf(_this.$listeners)) {
                let array = _this.$listeners.filter((value) => {
                    return value.reference === reference;
                });
                !_this.isArrayIf(array) && setListener();
            } else {
                setListener();
            }
        },

        /**
         *
         * @param type
         * @param reference
         * @param listener
         * @param callback
         */
        removeListener: (type, reference, listener, callback) => {
            const deleteListener = (object) => {
                if (_this.isElement(object.listener) || _this.isXMLHttpRequest(object.listener) || _this.isObjectIf(object.listener)) {
                    object.listener.removeEventListener(type, listener);
                    _this.$listeners = _this.$listeners.filter((value) => {
                        return value.reference !== reference;
                    });
                    (typeof(callback) === 'function') && callback(true);
                }
            };

            if (_this.isArrayIf(_this.$listeners)) {
                let array = _this.$listeners.filter((value) => {
                    return value.reference === reference;
                });
                if (_this.isArrayIf(array)) {
                    deleteListener(array[0]);
                } else {
                    (typeof(callback) === 'function') && callback(true);
                }
            } else {
                (typeof(callback) === 'function') && callback(true);
            }
        },

        /**
         *
         * @param reference
         */
        getListener: (reference) => {
            let array = _this.$listeners.filter((value) => {
                return value.reference === reference;
            });
            if (_this.isArrayIf(array)) {
                return array[0];
            }
        },

        /**
         *
         * @param data
         * @param field
         * @param prefix
         * @param empty
         * @returns {string}
         */
        verifyField: (data, field, prefix, empty) => {
            let array = field.split('.');
            if (_this.isArrayIf(array)) {
                let length = array.length, temp = data;
                for (let i = 0; length > i; i++) {
                    if (_this.isObjectIf(temp) && temp.hasOwnProperty(array[i])) {
                        temp = !_this.isEmpty(temp[array[i]]) ? temp[array[i]] : empty;
                    } else {
                        temp = empty;
                    }
                    if (length === (i + 1)) {
                        return ((prefix && !!temp) ? `${prefix}${temp}` : temp);
                    }
                }
            }
        },

        /**
         *
         * @param number
         * @param length
         * @returns {string}
         */
        padWithLeadingZeros: (number, length) => {
            return String(number).padStart(length, '0').toString();
        },

        /**
         *
         * @param original
         * @param current
         * @param target
         * @returns {*}
         */
        calcSize: (original, current, target) => {
            /**
             *
             * @param original
             * @param current
             * @param target
             * @returns {number}
             */
            const calcSizeSubtract = (original, current, target) => {
                return (target - ((target / 100) * (((original - current) / original) * 100)));
            };

            /**
             *
             * @param original
             * @param current
             * @param target
             * @returns {*}
             */
            const calcSizeAddition = (original, current, target) => {
                return (target + ((target / 100) * (((current - original) / original) * 100)));
            };

            if (original >= current) {
                return calcSizeSubtract(original, current, target);
            } else if (original < current) {
                return calcSizeAddition(original, current, target);
            }
        },

        /**
         *
         * @param document
         */
        openFullScreen: (document) => {
            if (document.requestFullscreen) {
                document.requestFullscreen();
            } else if (document.webkitRequestFullscreen) { /* Safari */
                document.webkitRequestFullscreen();
            } else if (document.msRequestFullscreen) { /* IE11 */
                document.msRequestFullscreen();
            } else if (document.mozRequestFullScreen) {/* Firefox */
                document.mozRequestFullScreen();
            }
        },

        /**
         *
         * @param document
         */
        closeFullScreen(document) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {/* Firefox */
                document.mozCancelFullScreen();
            }
        },

        fullScreen: () => {
            if ($.isFunction($.fn.confirm)) {
                _this.$jQuery.confirm({
                    title: '<img class="img-medium" src="./public/img/monograma-gold.png" />',
                    content: false,
                    theme: 'supervan',
                    closeIcon: false,
                    animation: 'scale',
                    draggable: false,
                    buttons: {
                        confirm: {
                            text: 'Visualizar em tela cheia',
                            btnClass: 'btn-default',
                            action: () => {
                                _this.openFullScreen(window.document.documentElement);
                            }
                        },
                        cancel: {
                            text: 'Não, obrigado',
                            btnClass: 'btn-default',
                            action: _this.void_
                        }
                    }
                });
            }
        },

        /**
         *
         * @param _this_current
         * @param name = occurrence
         */
        effectFadeIn: (_this_current, name) => {
            const execute = function () {
                /** @namespace this.modal */
                this[name].alpha += 0.1;
                (this[name].alpha >= 1) && this.removeEventListener('tick', _this.$effectFadeIn);
            };

            _this.$effectFadeIn = execute.bind(_this_current);
            _this_current.addEventListener('tick', _this.$effectFadeIn);
            _this_current[name].alpha = 0;
        },

        /**
         *
         * @param options
         * @param objectResponse
         */
        ajax: (options, objectResponse) => {
            const {method, action, data, type} = options;
            /** @namespace _this.$jQuery */
            _this.$jQuery.ajaxSetup({headers: {'X-HTTP-Method-Override': (!_this.isEmpty(method) ? method : 'GET')}});

            const resetFormData = (response) => {
                _this.$FormData = (new FormData());
                (typeof(objectResponse.always) === 'function') && objectResponse.always(response);
            };

            const execute = () => {
                _this.$jQuery.ajax({
                    method: method,
                    url: action,
                    data: _this.$FormData,
                    contentType: false,
                    processData: false,
                    dataType: !_this.isEmpty(type) ? type : 'xml',
                    beforeSend: ((typeof(objectResponse.beforeSend) === 'function') ? objectResponse.beforeSend : _this.void_)
                })
                    .done((typeof(objectResponse.done) === 'function') ? objectResponse.done : _this.void_)
                    .fail((typeof(objectResponse.fail) === 'function') ? objectResponse.fail : _this.void_)
                    .always((typeof(objectResponse.always) === 'function') ? resetFormData : _this.void_);
            };

            if (_this.isArrayIf(data)) {
                _this.recursiveSyncMap(data, 0, data.length, (item, next, stop) => {
                    let name = _this.verifyField(item, 'name', false, false);
                    if (name) {
                        _this.$FormData.append(name, _this.verifyField(item, 'value', false, null));
                        next(true);
                    } else {
                        stop(true)
                    }
                }, () => {
                    execute();
                });
            } else {
                _this.$FormData = undefined;
                execute();
            }
        },

        /**
         *
         * @param xml
         */
        xmlToJson: (xml) => {
            let object = {};

            if (xml.nodeType === 1) {
                if (xml.attributes.length > 0) {
                    object['@attributes'] = {};
                    _this.recursiveSyncMap(xml.attributes, 0, xml.attributes.length, (item, next) => {
                        object['@attributes'][item.nodeName] = item.value;
                        next(true);
                    });
                }
            } else if (xml.nodeType === 3) {
                object = xml.nodeValue;
            }

            if (xml.hasChildNodes()) {
                _this.recursiveSyncMap(xml.childNodes, 0, xml.childNodes.length, (item, next) => {
                    let nodeName = item.nodeName;
                    if (typeof(object[nodeName]) === 'undefined') {
                        object[nodeName] = _this.xmlToJson(item);
                    } else {
                        if (typeof(object[nodeName].push) === 'undefined') {
                            let old = object[nodeName];
                            object[nodeName] = [];
                            object[nodeName].push(old);
                        }
                        object[nodeName].push(_this.xmlToJson(item));
                    }
                    next(true);
                });
            }

            return object;
        },

        /**
         *
         * @param uri
         * @param callback
         */
        getXml: (uri, callback) => {
            _this.ajax({method: 'GET', action: uri}, {
                done: (response) => {
                    callback(_this.xmlToJson(response));
                },
                fail: (response) => {
                    console.log(response);
                },
                always: _this.void_
            });
        },

        /**
         *
         * @param array
         */
        createMenu: (array) => {
            const {
                uri,
                _this_index,
                _this_index: {
                    occ_logo_large,
                    occ_logo_small,
                    occ_background,
                    occ_background_effect,
                    occ_background_dynamic,
                    occ_menu_marker
                }
            } = _this.state;

            _this.recursiveSyncMap(array, 0, array.length, (item, next, stop) => {
                let name = _this.verifyField(item, 'name', false, null);
                if (!_this.isEmpty(name)) {
                    let btn_name = AllTap.verifyField(item, 'button', false, null);

                    if (_this.isEmpty(btn_name)) {
                        let array_name = name.split('-');
                        btn_name = (_this.isArrayIf(array_name) && (array_name.length > 1)) ? `btn_${array_name.join('_')}` : `btn_${array_name.join()}`;
                    }

                    /**
                     *
                     * If is a button video
                     */
                    if (_this.verifyField(item, 'occ_video', false, false)) {
                        _this.$jQuery(_this_index[btn_name]).on('click', () => {
                            _this.$jQuery('#load_iframe_index').attr('src', '');
                            let content_video = _this.$jQuery('.content-video');
                            content_video.show();
                            content_video.find('.video-play').trigger('play');
                            occ_menu_marker.gotoAndStop(name);
                            occ_menu_marker.occ_arrow_up.play();
                        });

                        _this.$jQuery('.close-content-video').on('click', () => {
                            let content_video = _this.$jQuery('.content-video');
                            content_video.toggleClass('animate__fadeIn').toggleClass('animate__fadeOut');
                            /**
                             *
                             * Time control for effect
                             */
                            _this.throttle('helpers-content-video', () => {
                                if (content_video.hasClass('animate__fadeOut')) {
                                    content_video.hide();
                                    content_video.find('.video-play').trigger('pause').trigger('load');
                                    _this.$page_default();
                                    content_video.toggleClass('animate__fadeOut').toggleClass('animate__fadeIn');
                                }
                            }, 500);
                        });
                        /**
                         *
                         * If is a button video
                         */
                    } else if (_this.verifyField(item, 'occ_tour', false, false)) {
                        _this.$jQuery(_this_index[btn_name]).on('click', () => {
                            _this.$jQuery('#load_iframe_index').attr('src', '');
                            let content_tour = _this.$jQuery('.content-tour');
                            content_tour.show();
                            occ_menu_marker.gotoAndStop(name);
                            occ_menu_marker.occ_arrow_up.play();
                        });

                        _this.$jQuery('.close-content-tour').on('click', () => {
                            let content_tour = _this.$jQuery('.content-tour');
                            content_tour.toggleClass('animate__fadeIn').toggleClass('animate__fadeOut');
                            /**
                             *
                             * Time control for effect
                             */
                            _this.throttle('helpers-content-tour', () => {
                                if (content_tour.hasClass('animate__fadeOut')) {
                                    content_tour.hide();
                                    _this.$page_default();
                                    content_tour.toggleClass('animate__fadeOut').toggleClass('animate__fadeIn');
                                }
                            }, 500);
                        });
                    } else {
                        let page = () => {
                            _this.$jQuery('#load_iframe_index').attr('src', `${uri}/src/${name}/${name}.html`);
                            occ_logo_large && (occ_logo_large.visible = _this.verifyField(item, 'occ_logo_large', false, false));
                            occ_logo_small && (occ_logo_small.visible = _this.verifyField(item, 'occ_logo_small', false, false));
                            occ_background && (occ_background.visible = _this.verifyField(item, 'occ_background', false, false));
                            let occ_background_effect_visible = _this.verifyField(item, 'occ_background_effect', false, false);
                            occ_background_effect && (occ_background_effect.visible = occ_background_effect_visible);
                            occ_background_effect && (occ_background_effect_visible && occ_background_effect.gotoAndPlay(0));
                            occ_background_dynamic && occ_background_dynamic.gotoAndStop(name);

                            occ_menu_marker.gotoAndStop(name);
                            occ_menu_marker.occ_arrow_up.play();
                        };

                        if (_this.verifyField(item, 'page_default', false, false)) {
                            _this.$page_default = page;
                        }

                        _this.$jQuery(_this_index[btn_name]).on('click', page);
                    }
                    next(true);
                } else {
                    stop(true);
                }
            });
        },

        /**
         *
         * @returns {{object: null, pin: null, clone(*): void, setNumber(*=, *=, *=, *=, *=, *=, *=): void, setColor(*=, *=): void, setCoordinate(*=, *=): void, setAnimate(*): void, setAction(*, *=, *=): void}}
         */
        clonePin: () => {
            return {
                object: null,

                /**
                 *
                 * @returns {null}
                 */
                get pin() {
                    return this.object;
                },

                /**
                 *
                 * @param object
                 */
                set pin(object) {
                    this.object = object;
                },

                /**
                 *
                 * @param name
                 */
                clone(name) {
                    const _this_current = _this.state[`_this_${name}`], {content_pin} = _this_current;
                    this.pin = content_pin.clone();
                },

                /**
                 *
                 * @param number
                 * @param x
                 * @param y
                 * @param color
                 * @param font
                 * @param size
                 * @param weight
                 */
                setNumber(number, x, y, color, font, size, weight) {
                    const {occ_pin_number} = this.object;
                    if (_this.isIntegerOrFloat(number) && _this.verifyField(occ_pin_number, 'text', false, false)) {
                        occ_pin_number.text = _this.padWithLeadingZeros(number, 2);
                    }
                    if (!_this.isEmpty(color) && color.length === 6 && _this.verifyField(occ_pin_number, 'color', false, false)) {
                        occ_pin_number.color = `#${color.toUpperCase()}`;
                    }
                    if (!_this.isEmpty(font) && _this.isInteger(size) && _this.verifyField(occ_pin_number, 'font', false, false)) {
                        occ_pin_number.font = `${(!_this.isEmpty(weight) ? `${weight.toLowerCase()} ` : ``)}${size}px '${font}'`;
                    }
                    x = _this.toIntegerOrFloat(x);
                    if (_this.isIntegerOrFloat(x) && _this.verifyField(occ_pin_number, 'x', false, false)) {
                        occ_pin_number.x = x;
                    }
                    y = _this.toIntegerOrFloat(y);
                    if (_this.isIntegerOrFloat(y) && _this.verifyField(occ_pin_number, 'y', false, false)) {
                        occ_pin_number.y = y;
                    }
                },

                /**
                 *
                 * @param color
                 * @param occ
                 */
                setColor(color, occ) {
                    const
                        occ_color = _this.verifyField(this.object, occ, false, []),
                        children = _this.verifyField(occ_color, 'children', false, []);

                    if (!_this.isEmpty(color) && color.length === 6 && _this.isArrayIf(children)) {
                        if (_this.verifyField(children[0], 'graphics._fill.style', false, false)) {
                            children[0].graphics._fill.style = `#${color.toUpperCase()}`;
                        } else if (_this.verifyField(children[0], 'graphics._stroke.style', false, false)) {
                            children[0].graphics._stroke.style = `#${color.toUpperCase()}`;
                        }
                    }
                },

                /**
                 *
                 * @param x
                 * @param y
                 */
                setCoordinate(x, y) {
                    x = _this.toIntegerOrFloat(x);
                    y = _this.toIntegerOrFloat(y);
                    _this.isIntegerOrFloat(x) && (this.object.x = x);
                    _this.isIntegerOrFloat(y) && (this.object.y = y);
                },

                /**
                 *
                 * @param visible
                 */
                setAnimate(visible) {
                    const {occ_pin_animate} = this.object;
                    occ_pin_animate.visible = visible;
                },

                /**
                 *
                 * @param enabled
                 * @param cursor
                 * @param uri
                 */
                setAction(enabled, cursor, uri) {
                    this.object.mouseEnabled = enabled;
                    !_this.isEmpty(cursor) && (this.object.cursor = cursor);
                    !_this.isEmpty(uri) && (this.object.uri = uri);
                }
            }
        },

        /**
         *
         * @returns {{object: null, pinText: null, clone(*): void, setText(*=, *=, *=, *=, *=): void, setNumber(*=, *=, *=, *=, *=, *=, *=): void, setColor(*=, *=): void, setCoordinate(*=, *=): void, setAnimate(*): void, setAction(*, *=, *=): void}}
         */
        clonePinText: () => {
            return {
                object: null,

                /**
                 *
                 * @returns {null}
                 */
                get pinText() {
                    return this.object;
                },

                /**
                 *
                 * @param object
                 */
                set pinText(object) {
                    this.object = object;
                },

                /**
                 *
                 * @param name
                 */
                clone(name) {
                    const _this_current = _this.state[`_this_${name}`], {content_pin_text} = _this_current;
                    this.pinText = content_pin_text.clone();
                },

                /**
                 *
                 * @param string
                 * @param color
                 * @param font
                 * @param size
                 * @param weight
                 */
                setText(string, color, font, size, weight) {
                    const {occ_text} = this.object;
                    if (!_this.isEmpty(string) && _this.verifyField(occ_text, 'text', false, false)) {
                        occ_text.text = string.toString();
                    }
                    if (!_this.isEmpty(color) && color.length === 6 && _this.verifyField(occ_text, 'color', false, false)) {
                        occ_text.color = `#${color.toUpperCase()}`;
                    }
                    if (!_this.isEmpty(font) && _this.isInteger(size) && _this.verifyField(occ_text, 'font', false, false)) {
                        occ_text.font = `${(!_this.isEmpty(weight) ? `${weight.toLowerCase()} ` : ``)}${size}px '${font}'`;
                    }
                },

                /**
                 *
                 * @param number
                 * @param x
                 * @param y
                 * @param color
                 * @param font
                 * @param size
                 * @param weight
                 */
                setNumber(number, x, y, color, font, size, weight) {
                    const {occ_pin: {occ_pin_number}} = this.object;
                    if (_this.isIntegerOrFloat(number) && _this.verifyField(occ_pin_number, 'text', false, false)) {
                        occ_pin_number.text = _this.padWithLeadingZeros(number, 2);
                    }
                    if (!_this.isEmpty(color) && color.length === 6 && _this.verifyField(occ_pin_number, 'color', false, false)) {
                        occ_pin_number.color = `#${color.toUpperCase()}`;
                    }
                    if (!_this.isEmpty(font) && _this.isInteger(size) && _this.verifyField(occ_pin_number, 'font', false, false)) {
                        occ_pin_number.font = `${(!_this.isEmpty(weight) ? `${weight.toLowerCase()} ` : ``)}${size}px '${font}'`;
                    }
                    x = _this.toIntegerOrFloat(x);
                    if (_this.isIntegerOrFloat(x) && _this.verifyField(occ_pin_number, 'x', false, false)) {
                        occ_pin_number.x = x;
                    }
                    y = _this.toIntegerOrFloat(y);
                    if (_this.isIntegerOrFloat(y) && _this.verifyField(occ_pin_number, 'y', false, false)) {
                        occ_pin_number.y = y;
                    }
                },

                /**
                 *
                 * @param color
                 * @param occ
                 */
                setColor(color, occ) {
                    const
                        {occ_pin} = this.object,
                        occ_color = _this.verifyField(occ_pin, occ, false, []),
                        children = _this.verifyField(occ_color, 'children', false, []);

                    if (!_this.isEmpty(color) && color.length === 6 && _this.isArrayIf(children)) {
                        if (_this.verifyField(children[0], 'graphics._fill.style', false, false)) {
                            children[0].graphics._fill.style = `#${color.toUpperCase()}`;
                        } else if (_this.verifyField(children[0], 'graphics._stroke.style', false, false)) {
                            children[0].graphics._stroke.style = `#${color.toUpperCase()}`;
                        }
                    }
                },

                /**
                 *
                 * @param x
                 * @param y
                 */
                setCoordinate(x, y) {
                    x = _this.toIntegerOrFloat(x);
                    y = _this.toIntegerOrFloat(y);
                    _this.isIntegerOrFloat(x) && (this.object.x = x);
                    _this.isIntegerOrFloat(y) && (this.object.y = y);
                },

                /**
                 *
                 * @param visible
                 */
                setAnimate(visible) {
                    const {occ_pin: {occ_pin_animate}} = this.object;
                    occ_pin_animate.visible = visible;
                },

                /**
                 *
                 * @param enabled
                 * @param cursor
                 * @param uri
                 */
                setAction(enabled, cursor, uri) {
                    this.object.mouseEnabled = enabled;
                    !_this.isEmpty(cursor) && (this.object.cursor = cursor);
                    !_this.isEmpty(uri) && (this.object.uri = uri);
                }
            }
        },

        /**
         *
         * @param current
         * @param name
         * @param single
         */
        createMarkers: (current, name, single) => {
            let current_name = (_this.isEmpty(single) ? `${name}` : `${current}`);

            const
                {uri} = _this.state,
                _this_current = _this.state[`_this_${current_name}`],
                {
                    currentFrame,
                    occ_modal,
                    occ_modal_background,
                    btn_close
                } = _this_current;

            let
                current_frame = _this.padWithLeadingZeros(currentFrame, 2),
                content_plant = _this_current[`content_plant_${current_frame}`],
                content_text = _this_current[`content_text_${current_frame}`];

            content_text.removeAllChildren();
            content_plant.content_btn.removeAllChildren();

            _this.getXml(`./src/${(!_this.isEmpty(current) ? `${current}` : ``)}${(_this.isEmpty(single) ? `/${current_name}` : ``)}/${name}.xml`, (response) => {
                let
                    bitmap = null,
                    children_temp = null,
                    array = _this.verifyField(response, 'products.product', false, []),
                    default_color = '0xCCCCCC',
                    default_x = 140;
                //default_y = 36;

                /**
                 * Clone pin text
                 */
                let
                    X = 0;
                //Y = 0;

                _this.recursiveSyncMap(array, 0, array.length, (item, next) => {
                    const pin = _this.clonePin(), pin_text = _this.clonePinText();
                    /**
                     * Clone pin
                     */
                    pin.clone(current_name);
                    pin.setNumber(_this.verifyField(item, 'itemPin.#text', false, 0), null, 12.2);
                    pin.setColor(_this.verifyField(item, 'colorPin.#text', false, default_color).substring(2), 'occ_pin_color');
                    pin.setColor(default_color.substring(2), 'occ_pin_round');
                    pin.setColor(default_color.substring(2), 'occ_pin_animate.round_static');
                    pin.setColor(default_color.substring(2), 'occ_pin_animate.round_animate.round_default');
                    pin.setColor(default_color.substring(2), 'occ_pin_animate.round_animate.round_copy');
                    pin.setCoordinate(_this.verifyField(item, 'xPin.#text', false, 0), _this.verifyField(item, 'yPin.#text', false, 0));
                    /**
                     * Clone pin text
                     */
                    pin_text.clone(current_name);

                    let
                        /**
                         * Clone pin text
                         */
                        _id = _this.verifyField(item, '@attributes.id', false, 0),
                        image = _this.verifyField(item, 'image.#text', false, 0),
                        status = Boolean(parseInt(_this.verifyField(item, 'status.#text', false, 0)));

                    if (!_this.isIdCompound(_id)) {
                        let text = _this.verifyField(item, 'name.#text', false, false);

                        /**
                         * Clone pin text
                         */
                        pin_text.setText(text, '333333', 'Helvetica', 16, 'bold');
                        pin_text.setNumber(_this.verifyField(item, 'itemPin.#text', false, 0), null, -7.2);
                        pin_text.setColor(_this.verifyField(item, 'colorPin.#text', false, default_color).substring(2), 'occ_pin_color');
                        pin_text.setColor(default_color.substring(2), 'occ_pin_round');
                        pin_text.setColor(default_color.substring(2), 'occ_pin_animate.round_static');
                        pin_text.setColor(default_color.substring(2), 'occ_pin_animate.round_animate.round_default');
                        pin_text.setColor(default_color.substring(2), 'occ_pin_animate.round_animate.round_copy');
                        //pin_text.setCoordinate(0, Y);
                        pin_text.setCoordinate(X, 10);

                        let count = ((text.length * 9) + 37);

                        X += count < default_x ? default_x : count;
                    }

                    if (image && status) {
                        /**
                         * Clone pin
                         */
                        pin.setAnimate(true);
                        pin.setAction(true, 'pointer', `${uri}/${image}`);

                        /**
                         * Clone pin text
                         */
                        if (!_this.isIdCompound(_id)) {
                            pin_text.setAnimate(true);
                            pin_text.setAction(true, 'pointer', `${uri}/${image}`);
                        }

                        /**
                         *
                         * @param event
                         */
                        let execute = (event) => {
                            bitmap && occ_modal.removeChild(bitmap);
                            bitmap = (new _this.$createJs.Bitmap(event.currentTarget.uri));
                            bitmap.scaleX = _this.verifyField(item, 'scaleX.#text', false, 0);
                            bitmap.scaleY = _this.verifyField(item, 'scaleY.#text', false, 0);
                            occ_modal.addChild(bitmap);
                            occ_modal.y = _this.verifyField(item, 'y.#text', false, 90);
                            occ_modal.x = ((_this_current.nominalBounds.width / 2) - parseFloat(_this.verifyField(item, 'x.#text', false, 0)));

                            children_temp = _this_current.children;

                            _this_current.children = _this_current.children.filter((value) => {
                                return (value.name === 'btn_close' || value.name === 'occ_modal' || value.name === 'occ_modal_background');
                            });

                            occ_modal_background.visible = true;

                            btn_close.visible = true;

                            _this.effectFadeIn(_this_current, 'occ_modal');
                        };

                        /**
                         * Clone pin
                         */
                        _this.$jQuery(pin.pin).on('click', execute);

                        /**
                         * Clone pin text
                         */
                        if (!_this.isIdCompound(_id)) {
                            _this.$jQuery(pin_text.pinText).on('click', execute);
                        }

                        _this.$jQuery(btn_close).on('click', () => {
                            bitmap && occ_modal.removeChild(bitmap);

                            _this_current.children = children_temp;

                            occ_modal_background.visible = false;

                            btn_close.visible = false;
                        });
                    } else {
                        /**
                         * Clone pin
                         */
                        pin.setAnimate(false);
                        pin.setAction(false, 'default');

                        /**
                         * Clone pin text
                         */
                        if (!_this.isIdCompound(_id)) {
                            pin_text.setAnimate(false);
                            pin_text.setAction(false, 'default');
                        }
                    }
                    /**
                     * Clone pin
                     */
                    content_plant.content_btn.addChild(pin.pin);

                    /**
                     * Clone pin text
                     */
                    if (!_this.isIdCompound(_id)) {
                        content_text.addChild(pin_text.pinText);
                        //Y += default_y;
                    }

                    next(true);
                });
            });
        },

        /**
         *
         * @param document
         * @param current
         * @param array
         */
        createPaginate: (document, current, array) => {
            const {uri} = _this.state;

            let pages = [];

            /**
             *
             * Include app.css in head of iFrame.
             */
            _this.$jQuery(document).find('#load_iframe_level_two').on('load', () => {
                _this.$jQuery('<link/>', {
                    media: 'all',
                    type: 'text/css',
                    rel: 'stylesheet',
                    href: `${_this.state.uri}/public/css/app.min.css`
                }).appendTo(_this.$jQuery(document).find('#load_iframe_level_two').contents().find('head'));
            });

            _this.recursiveSyncMap(array, 0, array.length, (item, next, stop) => {
                let name = _this.verifyField(item, 'name', false, null);
                if (!_this.isEmpty(name)) {
                    let
                        btn_name = _this.verifyField(item, 'button', false, false),
                        page_default = _this.verifyField(item, 'page_default', false, false);

                    if (!btn_name) {
                        let array_name = name && name.split('-');
                        btn_name = (_this.isArrayIf(array_name) && (array_name.length > 1)) ? `btn_${array_name.join('_')}` : `btn_${array_name.join()}`;
                    }

                    if (page_default) {
                        _this.$jQuery(document).find('#load_iframe_level_two').attr('src', `${uri}/src/${current}/${name}/${name}.html`);
                    }

                    pages.push({
                        [btn_name]: () => {
                            _this.$jQuery(document).find('#load_iframe_level_two').attr('src', `${uri}/src/${current}/${name}/${name}.html`);
                        }
                    });
                    next(true);
                } else {
                    stop(true);
                }
            }, () => {
                _this.setState({..._this.state, [`_this_${current}_paginates`]: pages});
            });
        },

        /**
         *
         * @returns {{object: null, sun: null, reference(*): void, setCoordinate(*=): void}}
         */
        reference: () => {
            return {
                object: null,

                /**
                 *
                 * @returns {null}
                 */
                get sun() {
                    return this.object;
                },

                /**
                 *
                 * @param object
                 */
                set sun(object) {
                    this.object = object;
                },

                /**
                 *
                 * @param name
                 */
                reference(name) {
                    const _this_current = _this.state[`_this_${name}`], {occ_sun, parent: {input_slider}} = _this_current;
                    this.sun = {sun: occ_sun, input: input_slider};
                },

                /**
                 *
                 * @param x
                 */
                setCoordinate(x) {
                    const {sun, input: {content_tracker: {btn_slider}}} = this.object;
                    x = _this.toIntegerOrFloat(x);
                    if (_this.isIntegerOrFloat(x)) {
                        sun.gotoAndStop(_this.toInteger(x));
                        btn_slider.setTransform(x);
                    }
                }
            }
        },

        /**
         *
         * @param reference
         */
        syncHours: (reference) => {
            let current = 0;
            switch (_this.$hours) {
                case 7:
                    current = 50;
                    break;
                case 8:
                    current = 100;
                    break;
                case 9:
                    current = 150;
                    break;
                case 10:
                    current = 200;
                    break;
                case 11:
                    current = 250;
                    break;
                case 12:
                    current = 300;
                    break;
                case 13:
                    current = 350;
                    break;
                case 14:
                    current = 400;
                    break;
                case 15:
                    current = 450;
                    break;
                case 16:
                    current = 500;
                    break;
                case 17:
                    current = 550;
                    break;
            }

            (_this.$hours <= 6) && (current = 1);
            (_this.$hours >= 18) && (current = 600);

            let frame = 0;

            _this.$createJs.Ticker.framerate = 1000;

            let effectSunIn = () => {
                frame += 1;

                /** @namespace this.modal */
                reference.setCoordinate(frame);
                (frame >= current) && _this.$createJs.Ticker.removeEventListener('tick', effectSunIn);
            };

            _this.$createJs.Ticker.addEventListener('tick', effectSunIn);

            effectSunIn();
        },

        $functions: {
            btnSliderMouseDown: function () {
                const {data: {_stage, btn_slider}} = _this.getListener('create-sun-mousedown');
                btn_slider.offsetX = _this.toInteger((_stage.mouseX / _stage.scaleX) - btn_slider.x);
            },
            btnSliderPressMove: function () {
                const {data: {_stage, btn_slider, frames, reference}} = _this.getListener('create-sun-pressmove');
                let position = _this.toInteger((_stage.mouseX / _stage.scaleX) - btn_slider.offsetX);
                if (position >= 0 && position <= frames) {
                    reference.setCoordinate(position);
                }
            },
            /**
             *
             * @param event
             */
            canvasExtractPosition: function (event) {
                (event.type === 'touchmove') && event.preventDefault();
                const {data: {properties, input, padding, frames, reference}} = _this.getListener('create-sun-touchstart');
                let
                    width = _this.calcSize(properties.width, event.target.clientWidth, input.nominalBounds.width - padding),
                    height = _this.calcSize(properties.height, event.target.clientHeight, input.nominalBounds.height),
                    leftHorizontal = _this.calcSize(properties.width, event.target.clientWidth, input.x + (padding / 2)),
                    topVertical = _this.calcSize(properties.height, event.target.clientHeight, input.y),
                    rightHorizontal = (leftHorizontal + width),
                    bottomVertical = (topVertical + height),
                    positionX = _this.toInteger(event.targetTouches[0].clientX),
                    positionY = _this.toInteger(event.targetTouches[0].clientY),
                    //positionX = (event.clientX),
                    //positionY = (event.clientY),
                    positionTargetX = (positionX - leftHorizontal),
                    //positionTargetY = (positionY - topVertical),
                    position = _this.toInteger(((input.nominalBounds.width - padding) / width) * positionTargetX);

                if ((position >= 0 && position <= frames) && (positionX >= leftHorizontal && positionX <= rightHorizontal) && (positionY >= topVertical && positionY <= bottomVertical)) {
                    reference.setCoordinate((position === 1) ? 0 : (position === (frames - 1)) ? frames : position);
                }
            }
        },

        /**
         *
         * @param _canvas
         * @param _stage
         * @param name
         */
        createSun: (_canvas, _stage, name) => {
            const
                reference = _this.reference(),
                {properties} = _this.state[`_this_${name}_library`];

            reference.reference(name);

            const {input, input: {content_tracker: {btn_slider}}} = reference.sun;

            let
                padding = 60,
                frames = 600;

            _this.syncHours(reference);

            _this.$createJs.Touch.enable(_stage);
            _stage.mouseMoveOutside = true;
            _stage.preventSelection = true;

            _this.removeListener('mousedown', 'create-sun-mousedown', _this.$functions.btnSliderMouseDown, () => {
                _this.addListener(btn_slider, 'mousedown', 'create-sun-mousedown', _this.$functions.btnSliderMouseDown, {
                    _stage: _stage,
                    btn_slider: btn_slider
                })
            });

            _this.removeListener('pressmove', 'create-sun-pressmove', _this.$functions.btnSliderPressMove, () => {
                _this.addListener(btn_slider, 'pressmove', 'create-sun-pressmove', _this.$functions.btnSliderPressMove, {
                    _stage: _stage,
                    btn_slider: btn_slider,
                    frames: frames,
                    reference: reference
                })
            });

            _this.removeListener('touchstart', 'create-sun-touchstart', _this.$functions.canvasExtractPosition, () => {
                _this.addListener(_canvas, 'touchstart', 'create-sun-touchstart', _this.$functions.canvasExtractPosition, {
                    properties: properties,
                    input: input,
                    padding: padding,
                    frames: frames,
                    reference: reference
                })
            });

            _this.removeListener('touchmove', 'create-sun-touchmove', _this.$functions.canvasExtractPosition, () => {
                _this.addListener(_canvas, 'touchmove', 'create-sun-touchmove', _this.$functions.canvasExtractPosition, {
                    properties: properties,
                    input: input,
                    padding: padding,
                    frames: frames,
                    reference: reference
                })
            });

            //_canvas.addEventListener('mousemove', extractPosition);
            //_canvas.addEventListener('touchend', extractPosition);
            //_canvas.addEventListener('touchcancel', extractPosition);
        }
    };

    window.AllTap = (new HelpersAllTapController());
})();